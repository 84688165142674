<template>
  <b-container>
    <b-table :dark="dark" :hover="hover" :striped="striped" :outlined="outlined" :bordered="bordered" :small="small"
             :fixed="fixed" responsive :items="items" :fields="captions" :current-page="currentPage"
             :per-page="perPage" show-empty>
      <template slot="empty">
        <slot name="empty"></slot>
      </template>
      <template slot="picture" slot-scope="data">
        <b-image :src="asset(data.item.picture)" class="preview-image"></b-image>
      </template>
      <template slot="status" slot-scope="data">
        <b-badge :variant="data.item.statusVariant">{{data.item.status}}</b-badge>
      </template>
      <template slot="actions" slot-scope="data">
        <b-button v-for="action in actions" :variant="action.type" @click="action.handler(data.item)" class="mr-1"
                  :title="action.title" v-b-tooltip.hover>
          <i :class="action.icon"></i>
        </b-button>
      </template>
    </b-table>
    <nav>
      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="Préc."
                    next-text="Suiv." hide-goto-end-buttons/>
    </nav>
  </b-container>
</template>

<script>
  import {asset} from "../helpers";

  export default {
    name: 'c-table',
    inheritAttrs: false,
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      },
      tableData: {
        type: [Array, Function],
        default: () => []
      },
      fields: {
        type: [Array, Object],
        default: () => []
      },
      actions: {
        type: [Array, Object],
        default: () => []
      },
      perPage: {
        type: Number,
        default: 5
      },
      dark: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        currentPage: 1,
        asset
      }
    },
    created() {
      const pictureField = this.fields.find(field => field.key === 'picture')
      if (pictureField)
        this.asset = pictureField.asset
    },
    computed: {
      items: function () {
        const items = this.tableData
        return Array.isArray(items) ? items : items()
      },
      totalRows: function () {
        return this.getRowCount()
      },
      captions: function () {
        return this.fields.map(field => ({...field, class: 'v-align-middle'}))
      }
    },
    methods: {
      getRowCount: function () {
        return this.items.length
      }
    }
  }
</script>

<style scoped>
  .preview-image {
    max-height: 50px;
    max-width: 100%;
  }
</style>
