<template>
  <div class="root mb-4 p-2">
    <vue-custom-scrollbar class="container">
      <h6 class="parcel">
        <b-badge><i class="icon-layers"></i></b-badge>
        {{data.parcel.name}} ({{data.culture.name}})
      </h6>
      <ul class="progressbar">
        <li v-for="(step, i) in data.cultureSteps" :class="complete(step) ? 'active' : ''" @click="onSelect(i)"
            :data-selected="selected === i">
          {{step.name}}
        </li>
      </ul>
      <div class="bubble" :style="bubbleStyle">
        <b-form class="pt-3">
          <h5>
            <b-badge :variant="complete(step) ? 'success' : ''">&Eacute;tape {{selected + 1}}:</b-badge>
            {{step.name}}
          </h5>

          <b-form-group class="mb-3 mt-3 text-muted" label="Responsable">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text><i class="icon-user"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-select v-model="responsible" :options="responsibleOptions" :state="responsibleState"
                             aria-describedby="responsible-feedback">
                <template slot="first">
                  <option :value="null" disabled>-- Sélectionnez un responsable --</option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="responsible-feedback">
                Veuillez sélectionner un responsable
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group class="mb-3 mt-3 text-muted" label="Avancement">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text><i class="icon-pie-chart"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="range" class="form-control" min="0" max="100" step="1" v-model="progression"/>
              <b-input-group-append>
                <b-input-group-text>{{progression}} %</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group class="mb-3 mt-3 text-muted" label="Deadline">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text><i class="icon-hourglass"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="date" class="form-control" v-model="deadlineDay"/>
              <b-form-input type="time" class="form-control" v-model="deadlineTime"/>
            </b-input-group>
          </b-form-group>

          <b-form-group class="mt-4 text-center">
            <button-spinner variant="success" type="submit" class="px-4" @click="onUpdateTask"
                            :fetching="fetchingUpdateTask">
              Enregistrer
            </button-spinner>
          </b-form-group>
        </b-form>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>
<script>
  import {Api, Toast, Storage} from "../helpers";

  export default {
    props: ['data', 'staff'],
    data() {
      return {
        selected: 0,
        responsible: null,
        progression: 0,
        deadlineDay: '',
        deadlineTime: '',
        responsibleOptions: [],
        fetchingUpdateTask: false,
        submitted: false,
        error: null
      }
    },
    created() {
      // Select current step
      for (let i = 0; i < this.data.cultureSteps.length; i++) {
        if (!this.complete(this.data.cultureSteps[i])) {
          this.onSelect(i)
          break
        }
      }

      this.responsibleOptions = this.staff.map(item => ({
        text: item.name,
        value: {id: item.id, user: item.user}
      }))
    },
    mounted() {
      const container = this.$el.querySelector('.container')
      container.scrollLeft = this.selected * 160
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      step() {
        return this.data.cultureSteps[this.selected]
      },
      bubbleStyle() {
        const left = 20 + this.selected * 165
        return `left: ${left}px`
      },
      responsibleState() {
        return !this.submitted || this.responsible != null ? null : false
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      complete(step) {
        return step.progression >= 100
      },
      onSelect(index) {
        this.submitted = false
        this.error = null

        this.selected = index
        const step = this.data.cultureSteps[index]
        if(step.responsible)
        {
          this.responsible = {
            id: step.responsible.id,
            user: step.responsible.user,
          }
        }
        else {
          this.responsible = null
        }
        this.progression = step.progression
        if (step.deadline && step.deadline !== '') {
          const deadline = step.deadline.split(' ')
          this.deadlineDay = deadline[0]
          this.deadlineTime = deadline[1]
        }
        else {
          this.deadlineDay = ''
          this.deadlineTime = ''
        }
      },
      valid() {
        return this.responsible != null
      },
      onUpdateTask() {
        this.submitted = true
        if(!this.valid()) return

        this.fetchingUpdateTask = true
        const step = {
          parcelId: this.data.parcel.id,
          cultureStepId: this.step.id,
          responsible: this.responsible,
          progression: this.progression,
          deadline: this.deadlineDay + ' ' + this.deadlineTime
        }
        Api.post('/exploitation/task/update', {
          exploitationId: this.exploitationId,
          ...step
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.$emit('update', step)

              Toast.success('Etape de culture mise à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingUpdateTask = false
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  .root {
    border: 1px solid #bebebe;
  }

  .container {
    width: 100%;
    margin-top: 20px;
    z-index: 2;
    overflow-x: auto;
    position: relative;
    /*scroll-behavior: smooth;*/
  }

  .progressbar {
    counter-reset: step;
    display: flex;
    padding-top: 15px;
    padding-bottom: 390px;
  }

  div.bubble {
    border: 1px solid #bebebe;
    width: 480px;
    top: 125px;
    left: 373px;
    position: absolute;
    padding: 0 15px;
    border-radius: .5rem;
    text-align: left;
    cursor: default;

    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
  }

  div.bubble:before {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;

    top: -15px;
    left: 48px;
    bottom: auto;
    right: auto;
    border-width: 0 15px 15px 15px;
    border-color: #bebebe transparent;
  }

  div.bubble:after {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;

    top: -13px;
    left: 50px;
    bottom: auto;
    right: auto;
    border-width: 0 13px 13px;
    border-color: white transparent;
  }

  .progressbar li:not([data-selected]) > div.bubble {
    opacity: 0;
    visibility: hidden;
  }

  .progressbar li {
    position: relative;
    text-align: center;
    list-style: none;
    margin-right: 100px;
    cursor: pointer;
    width: 64px;
    text-overflow: ellipsis;
  }

  .progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    border: 2px solid #bebebe;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: white;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
    z-index: 2;

    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }

  .progressbar li:after {
    content: '';
    position: absolute;
    width: calc(100% + 70px);
    margin-left: 79px;
    height: 3px;
    background: #979797;
    top: 15px;
    left: -50%;
    z-index: 1;
    cursor: default;
  }

  .progressbar li.active:before {
    border-color: #3aac5d;
    background: #3aac5d;
    color: white
  }

  .progressbar li:hover:before {
    border-color: rgba(255, 197, 7, .7);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }

  .progressbar li[data-selected]:before {
    border-color: #FFC107;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  .progressbar li.active:after {
    background: #3aac5d;
  }

  .progressbar li.active

  -
  li:after {
    background: #3aac5d;
  }

  .progressbar li.active

  -
  li:before {
    border-color: #3aac5d;
    background: #3aac5d;
    color: white
  }

  .progressbar li:last-child:after {
    content: none;
  }
</style>
