<template>
  <b-button v-bind="$props" :disabled="fetching" @click.prevent="onClick">
    <b-spinner small class="mr-2" v-if="fetching"></b-spinner>
    <slot></slot>
  </b-button>
</template>

<script>
  export default {
    name: "ButtonSpinner",
    props: ['variant', 'block', 'type', 'fetching'],
    methods: {
      onClick() {
        this.$emit('click')
      }
    }
  }
</script>

<style scoped>

</style>
