<template>
  <b-form-group :class="containerClass" :invalid-feedback="invalidFeedback" :state="state">
    <label for="input-id" class="text-muted" v-if="label">{{label}}</label>
    <info-message v-if="infoMessage">
      {{infoMessage}}
    </info-message>

    <b-form-select v-if="isComboBox" class="form-control" v-model="input" v-bind="$props">
      <template slot="first">
        <slot name="first"></slot>
      </template>
    </b-form-select>

    <b-form-textarea v-else-if="isTextArea" class="form-control" :placeholder="placeholder"
                     v-model="input" rows="1"></b-form-textarea>

    <vue-tel-input v-else-if="isTelInput"
                   wrapperClasses="tel-input-container" inputClasses="form-control tel-input"
                   defaultCountry="CM"
                   :placeholder="placeholder" v-model="input">
    </vue-tel-input>

    <b-form-group v-else-if="isImageInput" class="input-wrapper text-center">
      <b-image thumbnail :src="picture" alt="Image" id="img" class="mb-2"></b-image>
      <b-container class="px-xl-5 px-lg-3 px-md-1" v-show="!hideSelect">
        <b-button variant="secondary" class="mr-1" @click="$refs.fileInput.click()">
          <i class="icon-picture"></i>
          Sélectionner une photo
        </b-button>
        <!-- <b-button variant="danger" @click="clearInput" title="Supprimer la photo" v-b-tooltip.hover v-if="textEntered">
          <i class="icon-trash"></i>
        </b-button> -->
        <input type="file" ref="fileInput" class="hidden" accept="image/*" @change="onPictureChange"/>
      </b-container>
    </b-form-group>

    <b-input-group v-else-if="isPriceInput">
      <b-form-input type="number" class="form-control" v-model="input" v-bind="$props" min="0" step="0.01"
                    id="input-id"/>
      <b-input-group-append>
        <b-input-group-text>{{currency}}</b-input-group-text>
      </b-input-group-append>
    </b-input-group>

    <b-input-group v-else-if="isQuantityInput">
      <b-form-input type="number" class="form-control" v-model="input" v-bind="$props" min="0" step="1"
                    id="input-id"/>
      <b-input-group-append>
        <b-input-group-text>{{unit}}</b-input-group-text>
      </b-input-group-append>
    </b-input-group>

    <b-input-group v-else-if="isQuantityUnitInput">
      <b-form-input type="number" class="form-control" v-model="input" v-bind="$props" min="0" step="1"
                    id="input-id"/>
      <b-input-group-append>
        <b-form-select v-model="input2" :options="units" id="unit-select" class="form-control" :disabled="unitDisabled">
          <template slot="first">
            <option :value="null">--</option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>

    <b-input-group v-else-if="isDatetimeInput">
      <b-input-group-prepend>
        <b-input-group-text><i class="icon-hourglass"></i></b-input-group-text>
      </b-input-group-prepend>
      <b-form-input type="date" class="form-control" v-model="input" :disabled="disabled"/>
      <b-form-input type="time" class="form-control" v-model="input2" :disabled="disabled"/>
    </b-input-group>

    <b-input-group v-else-if="isDateInput">
      <b-input-group-prepend>
        <b-input-group-text><i class="icon-calendar"></i></b-input-group-text>
      </b-input-group-prepend>
      <b-form-input type="date" class="form-control" v-model="input"/>
    </b-input-group>

    <b-form-group v-else class="input-wrapper">
      <b-form-input :type="inputType" class="form-control" v-model="input" v-bind="$props" id="input-id"/>
      <b-form-text class="bg-transparent cursor-pointer text-center text-dark" id="eye" @click="togglePasswordVisible"
                   :title="eyeTooltip" v-b-tooltip.hover v-if="isPasswordInput && textEntered">
        <i :class="eyeIcon"></i>
      </b-form-text>
    </b-form-group>
  </b-form-group>
</template>

<script>
  import {env} from "../helpers"

  export default {
    name: "Input",
    props: [
      'type', 'value', 'container-class', 'label', 'placeholder', 'state', 'default', 'currency', 'options', 'disabled',
      'unit', 'units', 'unit-disabled', 'time','hide-select'
    ],
    components: {
      VueTelInput : () => import('vue-tel-input')
    },
    data() {
      return {
        input: this.value,
        input2: this.unit || this.time,
        passwordVisible: false
      }
    },
    computed: {
      isPasswordInput() {
        return this.type === 'password'
      },
      isTextArea() {
        return this.type === 'textarea'
      },
      isImageInput() {
        return this.type === 'image'
      },
      isTelInput() {
        return this.type === 'tel'
      },
      isPriceInput() {
        return this.type === 'price'
      },
      isQuantityInput() {
        return this.type === 'quantity'
      },
      isQuantityUnitInput() {
        return this.type === 'quantity-unit'
      },
      isComboBox() {
        return this.type === 'select'
      },
      isDateInput() {
        return this.type === 'date'
      },
      isDatetimeInput() {
        return this.type === 'datetime'
      },
      inputType() {
        if (!this.type || (this.isPasswordInput && this.passwordVisible))
          return 'text'

        if (this.type === 'price')
          return 'number'

        return this.type
      },
      eyeTooltip() {
        return this.passwordVisible ? 'Masquer le mot de passe' : 'Afficher le mot de passe'
      },
      eyeIcon() {
        return this.passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'
      },
      textEntered() {
        return this.input && this.input.length > 0
      },
      picture() {
        return this.input ? this.input : env(this.default)
      },
      invalidFeedback() {
        if (this.$slots.default && typeof this.$slots.default !== 'string')
          return this.$slots.default[0].text

        return this.$slots.default
      },
      infoMessage() {
        if (this.$slots.infoMessage && typeof this.$slots.infoMessage !== 'string')
          return this.$slots.infoMessage[0].text
  
        return this.$slots.infoMessage
      }
    },
    watch: {
      input(val) {
        this.$emit('input', val)
      },
      input2(val) {
        // because is a value that can be accepted
        if (this.unit || this.unit==null) {
          this.$emit('update:unit', val)
        }
        // because the "" value can be accepted
        if (this.time || this.time == "")
          this.$emit('update:time', val)
      },
      value(val) {
        this.input = val
      }
    },
    methods: {
      togglePasswordVisible() {
        this.passwordVisible = !this.passwordVisible
      },
      onPictureChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.input = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      },
      clearInput() {
        this.input = null
      }
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
    margin-bottom: 0;
  }

  .form-control[type=password] {
    padding-right: 25px;
  }

  .form-control.is-invalid {
    background-image: none;
  }

  select.form-control.is-invalid {
    background-position: right calc() center;
  }

  input::-ms-reveal {
    display: none;
  }

  .input-wrapper {
    position: relative;
    margin-bottom: 0;
  }

  #eye {
    position: absolute;
    top: 4px;
    right: 11px;
    font-size: 12px;
    height: 12px;
    width: 12px;
  }

  #img {
    max-height: 200px;
    object-fit: cover;
  }

  #remove-img-btn {
    position: absolute;
    padding: 0 5px;
    top: -8px;
    right: -8px;
  }
  .required-input label{
    display: inline-flex;
  }
  .required-input label::after{
    content: "*";
    color: red;
    font-size: 1.2em;
    margin-top: -3px;
    margin-left: 1px;
  }
</style>
