// Containers
import {Api, Auth, Toast} from "../helpers";

const ExploitationContainer = () => import('@/containers/ExploitationContainer')

// Staff views
const Users = () => import('@/views/exploitation/staff/Users')
const Employees = () => import('@/views/exploitation/staff/Employees')
const Tasks = () => import('@/views/exploitation/staff/Tasks')
const Alerts = () => import('@/views/exploitation/staff/Alerts')

// ToDo: Solve lazy loading problem
// Parcels views
const Rec = () => import('@/views/exploitation/parcels/Rec')
const Parcels = () => import('@/views/exploitation/parcels/Parcels')
const Parcel = () => import('@/views/exploitation/parcels/Parcel')
const CreateParcel = () => import('@/views/exploitation/parcels/CreateParcel')

const Cultures = () => import('@/views/exploitation/parcels/Cultures')

//Cultures views
const CreateCulture = () => import('@/views/exploitation/cultures/CreateCulture')
const OwnCultures = () => import('@/views/exploitation/cultures/OwnCultures')
const PiaCultures = () => import('@/views/exploitation/cultures/PiaCultures')
const GestionCultures = () => import('@/views/exploitation/cultures/GestionCultures')

// Production views
const Productions = () => import('@/views/exploitation/production/Productions')
const Production = () => import('@/views/exploitation/production/Production')
const CreateProduction = () => import('@/views/exploitation/production/CreateProduction')
const DashBoardProduction = () => import('@/views/exploitation/production/Dashboard')
const DashboardHarvest = () => import('@/views/exploitation/production/HarvestDashboard')


const Harvests = () => import('@/views/exploitation/production/Harvests')
const CreateHarvest = () => import('@/views/exploitation/production/CreateHarvest')

const Harvest = () => import('@/views/exploitation/production/Harvest')

// Commerce views
const Products = () => import('@/views/exploitation/commerce/Products')
const Sales = () => import('@/views/exploitation/commerce/Sales')
const Sale = () => import('@/views/exploitation/commerce/Sale')

// Management views
const Warehouses = () => import('@/views/exploitation/management/Warehouses')
const CreateWarehouse = () => import('@/views/exploitation/management/CreateWarehouse')

const Warehouse = () => import('@/views/exploitation/management/Warehouse')
const Equipments = () => import('@/views/exploitation/management/Equipments')
const Inputs = () => import('@/views/exploitation/management/Inputs')
const ProductsEx = () => import('@/views/exploitation/management/Products')

const Settings = () => import('@/views/exploitation/Settings')

// Accounting views

const AccountingCapitalEntries = () => import('@/views/exploitation/accounting/CapitalEntries')
const AccountingResume = () => import('@/views/exploitation/accounting/Resume')
const AccountingSpents = () => import('@/views/exploitation/accounting/Spents')

const GlobalView = () => import('@/views/exploitation/GlobalView')

export default [
  {
    path: '/exploitation/:id',
    redirect: '/exploitation/:id/parcels/create',
    name: 'Exploitation',
    meta: {
      breadcrumb: {
        text: 'Exploitation'
      }
    },
    component: ExploitationContainer,
    children: [
      {
        path: '/exploitation/:id/staff/users',
        name: 'ExploitationUsers',
        meta: {
          breadcrumb: {
            text: 'Utilisateurs'
          }
        },
        component: Users
      },
      {
        path: '/exploitation/:id/staff/employees',
        name: 'ExploitationEmployees',
        meta: {
          breadcrumb: {
            text: 'Employés'
          }
        },
        component: Employees
      },
      /*{
        path: '/exploitation/:id/staff/tasks',
        name: 'ExploitationTasks',
        meta: {
          breadcrumb: {
            text: 'Travaux'
          }
        },
        component: Tasks
      },
      {
        path: '/exploitation/:id/staff/alerts',
        name: 'ExploitationAlerts',
        meta: {
          breadcrumb: {
            text: 'Alertes'
          }
        },
        component: Alerts
      },*/
      {
        path: '/exploitation/:id/validate-add',
        name: 'ExploitationUserValidation',
        component: {
          render(c) {
            return c('router-view')
          },
          async beforeCreate() {
            const id = this.$route.params.id
            try {
              const res = await Api.post('/exploitation/user/validate', {
                exploitationId: id,
                token: Auth.id()
              })

              if (res.data.status === 'success' && res.data.data) {
                this.$router.push('/exploitation/' + id)
              }
              else {
                this.$router.push('/error/404')
              }
            }
            catch (e) {
              Toast.error(e)
            }
          }
        }
      },


      {
        path: '/exploitation/:id/parcels/list',
        name: 'ExploitationParcels',
        meta: {
          breadcrumb: {
            text: 'Parcelles'
          }
        },
        component: Parcels
      },
      {
        path: '/exploitation/:id/parcels/create',
        name: 'ExploitationCreateParcel',
        meta: {
          breadcrumb: {
            text: 'Nouvelle parcelle'
          }
        },
        component: CreateParcel
      },
      {
        path: '/exploitation/:id/parcels/:pid',
        name: 'ExploitationParcel',
        meta: {
          breadcrumb: {
            text: 'Parcelle'
          }
        },
        component: Parcel
      },
      {
        path: '/exploitation/:id/dashboard',
        name: 'ExploitationGlobalView',
        meta: {
          breadcrumb: {
            text: 'Vue Globale'
          }
        },
        component: GlobalView
      },
      {
        path: '/exploitation/:id/rec',
        name: 'ExploitationRec',
        meta: {
          breadcrumb: {
            text: 'Recommandations'
          }
        },
        component: Rec
      },
      {
        path: '/exploitation/:id/cultures/create',
        name: 'ExploitationCreateCulture',
        meta: {
          breadcrumb: {
            text: 'Creation de culture'
          }
        },
        component: CreateCulture
      },
      {
        path: '/exploitation/:id/cultures/ownlist',
        name: 'ExploitationOwnCultures',
        meta: {
          breadcrumb: {
            text: "Mes cultures d'interet"
          }
        },
        component: OwnCultures
      },
      {
        path: '/exploitation/:id/cultures/pialist',
        name: 'ExploitationPiaCultures',
        meta: {
          breadcrumb: {
            text: "Cultures Pia"
          }
        },
        component: PiaCultures
      },
      {
        path: '/exploitation/:id/cultures/manage',
        name: 'ExploitationGestionCultures',
        meta: {
          breadcrumb: {
            text: 'Gestions des cultures'
          }
        },
        component: GestionCultures
      },
      {
        path: '/exploitation/:id/parcels/cultures/list',
        name: 'ExploitationCultures',
        meta: {
          breadcrumb: {
            text: 'Cultures'
          }
        },
        component: Cultures
      },
      {
        path: '/exploitation/:id/production/harvests/list',
        name: 'ExploitationHarvests',
        meta: {
          breadcrumb: {
            text: 'Récoltes'
          }
        },
        component: Harvests
      },
      {
        path: '/exploitation/:id/production/harvests/create',
        name: 'ExploitationCreateHarvest',
        meta: {
          breadcrumb: {
            text: 'Créer une récolte'
          }
        },
        component: CreateHarvest
      },
      {
        path: '/exploitation/:id/production/harvest/:harvestId',
        name: 'ExploitationHarvest',
        meta: {
          breadcrumb: {
            text: 'Récolte'
          }
        },
        component: Harvest
      },
      {
        path: '/exploitation/:id/production/harvest/:harvestId/dashboard',
        name: 'ExploitationDashboardHarvest',
        meta: {
          breadcrumb: {
            text: 'Tableau de bord'
          }
        },
        component: DashboardHarvest
      },


      {
        path: '/exploitation/:id/productions/list',
        name: 'ExploitationProductions',
        meta: {
          breadcrumb: {
            text: 'Productions'
          }
        },
        component: Productions
      },
      {
        path: '/exploitation/:id/production/create',
        name: 'ExploitationCreateProduction',
        meta: {
          breadcrumb: {
            text: 'Nouvelle Production'
          }
        },
        component: CreateProduction
      },
      {
        path: '/exploitation/:id/production/:prodId',
        name: 'ExploitationProduction',
        meta: {
          breadcrumb: {
            text: 'Production'
          }
        },
        component: Production
      },

      {
        path: '/exploitation/:id/production/:prodId/dashboard',
        name: 'ExploitationDashboardProduction',
        meta: {
          breadcrumb: {
            text: 'Tableau de bord'
          }
        },
        component: DashBoardProduction
      },


      {
        path: '/exploitation/:id/products/list',
        name: 'ExploitationProducts',
        meta: {
          breadcrumb: {
            text: 'Liste des produits'
          }
        },
        component: Products
      },
      {
        path: '/exploitation/:id/sales/list',
        name: 'ExploitationSales',
        meta: {
          breadcrumb: {
            text: 'Liste des Ventes'
          }
        },
        component: Sales
      },


      {
        path: '/exploitation/:id/sales/:saleId',
        name: 'ExploitationSale',
        meta: {
          breadcrumb: {
            text: 'Tableau de bord - Vente'
          }
        },
        component: Sale
      },

      {
        path: '/exploitation/:id/management/warehouses/list',
        name: 'ExploitationWarehouses',
        meta: {
          breadcrumb: {
            text: 'Entrepôts'
          }
        },
        component: Warehouses
      },
      {
        path: '/exploitation/:id/management/warehouses/create',
        name: 'ExploitationCreateWarehouse',
        meta: {
          breadcrumb: {
            text: 'Nouvel entrepôt'
          }
        },
        component: CreateWarehouse
      },
      {
        path: '/exploitation/:id/management/warehouses/:wid',
        name: 'ExploitationWarehouse',
        meta: {
          breadcrumb: {
            text: 'Entrepôt'
          }
        },
        component: Warehouse
      },
      {
        path: '/exploitation/:id/management/equipments/list',
        name: 'ExploitationEquipments',
        meta: {
          breadcrumb: {
            text: 'Matériel'
          }
        },
        component: Equipments
      },
      {
        path: '/exploitation/:id/management/inputs/list',
        name: 'ExploitationInputs',
        meta: {
          breadcrumb: {
            text: 'Intrants'
          }
        },
        component: Inputs
      },

      {
        path: '/exploitation/:id/management/products/list',
        name: 'ExploitationProductsEx',
        meta: {
          breadcrumb: {
            text: 'Produits'
          }
        },
        component: ProductsEx
      },


      {
        path: '/exploitation/:id/settings',
        name: 'ExploitationSettings',
        meta: {
          breadcrumb: {
            text: 'Paramètres'
          }
        },
        component: Settings
      },


      {
        path: '/exploitation/:id/accounting/capitalEntries/list',
        name: 'AccountingInputs',
        meta: {
          breadcrumb: {
            text: 'Entrées de Fond'
          }
        },
        component: AccountingCapitalEntries
      },

      {
        path: '/exploitation/:id/accounting/spents/list',
        name: 'AccountingSpents',
        meta: {
          breadcrumb: {
            text: 'Dépenses'
          }
        },
        component: AccountingSpents
      },

      {
        path: '/exploitation/:id/accounting/resume',
        name: 'AccountingResume',
        meta: {
          breadcrumb: {
            text: 'Bilan'
          }
        },
        component: AccountingResume
      },

    ]
  }
]
