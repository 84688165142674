import {Auth, Api, Store, Toast} from "../helpers";

export default {
  onlyAuth(args, to, from, next) {
    console.log('nous sommes dans User')

    if (Auth.isAuthenticated()) {
      const loadingToast = Toast.loading()
      Api.get('/user/notification/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            Store.commit('setNotifications', res.data.data)
            next()
          }
          else {
            Toast.error(res.data.error)
          }
        })
        .catch(e => {
          Toast.error(e)
        })
        .then(() => {
          loadingToast.goAway(100)
        })

      next()
    }
    else{
      if(Auth.isAuthenticatedAdmin()){
        if(to.path == '/logout'){
          Auth.logout()
          this.$router.push('/login')
        }
      }else{
        next('/login?redirect=' + to.path)
      } 
    }
  },
  onlyAdmin(args, to, from, next) {
    console.log("nous sommes dans l'only admin")
    if (Auth.isAuthenticatedAdmin()) {
      next()
    }
    else
      next('/login?redirect=' + to.path)
  },
  onlyGuest(args, to, from, next) {
    console.log('nous entrons ici ' + to.path)
    if (Auth.isAuthenticated())
      next('/home')
    else if (Auth.isAuthenticatedAdmin())
      next('/super-admin')
    else
      next()
  },
  onlyExploitationUser(args, to, from, next) {
    const id = to.params.id
    const loadingToast = Toast.loading()
    Api.get('/exploitation', {id})
      .then(res => {
        if (res.data.status === 'success' && res.data.data) {
          Store.commit('setExploitation', res.data.data)
          next()
        }
        else {
          Toast.error(res.data.error)
        }
      })
      .catch(e => {
        Toast.error(e)
      })
      .then(() => {
        loadingToast.goAway(100)
      })
  }
}
