import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)


// Prettier stringifier
export const str = obj => typeof obj === 'string' || typeof obj === 'number'
  ? obj + ''
  : JSON.stringify(obj, null, 4)

// Loads configs from .env file
export const env = (key, defaultValue) => process.env['VUE_APP_' + key] || defaultValue

// Adds host prefix to a route
export const api = route => {
  let url = env('SERVER_HOST')
  const port = env('SERVER_PORT')
  if (port && port.trim() !== '')
    url += ':' + port

  return url + route
}

// Adds assets root prefix to an asset path
export const asset = (path, _default) => path ? api(path) : _default

// Adds authorization token (Json Web Token) to request options
export const jwt = options => {
  if (!options)
    options = {}

  if (Auth.isAuthenticated()) {
    if (!options.headers)
      options.headers = {}

    options.headers.Authorization = 'Bearer ' + Auth.token()
  }

  return options
}

export const fetchAsBase64 = url => new Promise((resolve, reject) => {
  axios.get(url, {responseType: 'arraybuffer'})
    .then(res => {
      const image = new Buffer(res.data, 'binary').toString('base64')
      const result = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`

      resolve(result)
    })
    .catch(e => {
      reject(e)
    })
})

export const fetchAsBase64Sync = async url => await fetchAsBase64(url)

export const price = (p = -1) => {
  if (!p && p !== 0)
    return ''

  if (p < 0)
    p = ''

  const exploitation = Store.state.exploitation
  if (exploitation && exploitation.currency)
    p += ' ' + exploitation.currency

  return p
}


export const Api = {
  get(route, params, config) {
    return axios.get(api(route), {
      params,
      ...jwt(config)
    })
  },
  post(route, data, config) {
    return axios.post(api(route), data, jwt(config))
  }
}

export const Storage = {
  store(key, value) {
    return localStorage.setItem(key, JSON.stringify(value))
  },
  get(key) {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : item
  },
  remove(key) {
    return localStorage.removeItem(key)
  }
}

export const Store = new Vuex.Store({
  state: {
    exploitation: null,
    notifications: [],
    guide: true,
    guideStep: {}
  },
  getters: {
    exploitationId(state) {
      return state.exploitation ? state.exploitation.id : undefined
    },
    exploitationName(state) {
      return state.exploitation ? state.exploitation.name : undefined
    },
    exploitationDateCreated(state) {
      return state.exploitation ? state.exploitation.createdAt : undefined
    },
    unreadNotifications(state) {
      return state.notifications.filter(item => !item.isRead)
    },
    notificationsCount(state, getters) {
      return getters.unreadNotifications.length
    },
    messagesCount(state, getters) {
      return getters.unreadNotifications.filter(item => !item.isAlert).length
    },
    alertsCount(state, getters) {
      return getters.unreadNotifications.filter(item => item.isAlert).length
    },
    guideState(state) {
      return state.guide
    }
  },
  mutations: {
    setExploitation(state, exploitation) {
      state.exploitation = exploitation
    },
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    setGuideState(state) {
      state.guide = !state.guide
    }
  }
})

export const endGuide = function() {

  Store.commit('setGuideState')
}

export const Auth = {
  user() {

    /* for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      console.log(`${key}: ${value}`);
    } */
// Log all sessionStorage data
  /* console.log("Session Storage Data:");
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    const value = sessionStorage.getItem(key);
    console.log(`${key}: ${value}`);
  }     */
  
  return Storage.get('user')
  },
  id() {
    return this.user().id
  },
  token() {
    return this.user().token
  },
  authenticateUser(user, remember = false) {
    Storage.store('user', {...user, remember})
  },
  logout() {
    Storage.remove('user')
  },
  isAuthenticated() {
    const user = this.user()
    return user && user.id && user.token && !user.isSuperAdmin
  },
  isAuthenticatedAdmin() {
    const user = this.user()
    return user && user.id && user.token && user.hasOwnProperty('isSuperAdmin')
  },
}

export const Regex = {
  isEmail(str) {
    return str.match(/^[a-z0-9_.]+@[a-z0-9_\-]+\.[a-z]{2,12}$/i)
  },
  isEmptyOrEmail(str) {
    return str === '' || this.isEmail(str)
  },
  isPassword(str) {
    return str.length >= 8 && str.match(/[0-9]+/) && str.match(/[a-zA-Z]+/) && str.match(/[^a-zA-Z0-9]+/)
  },
  isEmptyOrPassword(str) {
    return str === '' || this.isPassword(str)
  },
  isPhoneNumber(str) {
    return str.match(/^\+?[0-9 ]{6,50}$/i)
  },
  isEmptyOrPhoneNumber(str) {
    return str === '' || this.isPhoneNumber(str)
  },
  isCMRPhoneNumber(str) {
    str = str.replace(/ /g, '')
    return str.indexOf('+237') === 0 || str.indexOf('00237') === 0
  },
  isNumber(str) {
    str += ''
    return str.match(/^[0-9]+([.,][0-9]+)?$/i)
  },
  isPositiveNumber(str) {
    return this.isNumber(str) && str >= 0
  },
  isInteger(str) {
    str += ''
    return str.match(/^[0-9]+$/i)
  },
  isPositiveInteger(str) {
    return this.isInteger(str) && str >= 0
  },
  isNullOrPositiveNumber(str) {
    return !str || this.isPositiveNumber(str)
  },
  isValidUserCode(str) {
    return this.isPositiveNumber(parseInt(str))
  },
  isValidPhoneConfirmationCode(str) {
    return this.isPositiveNumber(str) && (str + '').length === 6
  },
  isURL(str) {
    return str && str.match(/^http(s)?:\/\//i)
  },
  isNullOrURL(str) {
    return !str || this.isURL(str)
  },
  isLongitude(longitude) {
    return longitude >= -180 && longitude <= 180
  },
  isLatitude(latitude) {
    return latitude >= -90 && latitude <= 90
  },
  isValidIDNumber(str) {
    return !!str
  }
}

export const String = {
  hiddenPhoneNumber(str) {
    if (!str)
      return str

    str = str.replace(/ /g, '')
    return str.slice(0, -4).replace(/./g, '*') + str.slice(-4)
  },
  toNDigits(number, n) {
    for (let i = 0; i < n; i++)
      number = '0' + number

    return number.slice(-n)
  }
}

export const Toast = {
  options: {
    duration: 5000,
    position: 'top-center',
    theme: 'bubble',
    iconPack: 'fontawesome',
    action: {
      text: 'Fermer',
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      }
    }
  },
  loadingToast: {
    visible: false,
    toast: null,
    goAway(delay) {
      if (this.toast)
        this.toast.goAway(delay || 100)
      this.visible = false
    }
  },
  guideOptions: {
    duration: null,
    position: 'top-right',
    theme: 'outline',
    iconPack: 'fontawesome',
    icon: 'fa-handshake-o',
    className: ["guide-toast"],
    class: ["guide-toast"],
    containerClass: ["guide-container"],
    singleton: true,
    action: [
      {
        text: 'Etape Precedente',
        onClick: (e, toastObject) => {

        }
      },
    ]
  },
  _(msg) {
    return !msg
      ? '---'
      : typeof msg === 'string'
        ? msg
        : msg.message;
  },
  show(msg) {
    return Vue.toasted.show(this._(msg), this.options)
  },
  success(msg) {
    return Vue.toasted.success(this._(msg), {...this.options, icon: 'check'})
  },
  info(msg) {
    return Vue.toasted.info(this._(msg), {...this.options, icon: 'info'})
  },
  error(msg) {
    return Vue.toasted.error(this._(msg), {...this.options, icon: 'remove'})
  },
  loading(msg) {
    if (!this.loadingToast.visible) {
      Vue.toasted.clear()
      this.loadingToast.toast = Vue.toasted.show(msg || 'Chargement...', {
        duration: 5 * 60 * 1000, // 5 mins
        position: 'top-center',
        iconPack: 'fontawesome',
        icon: 'hourglass'
      })
      this.loadingToast.visible = true
    }

    return this.loadingToast
  },
  guide_msg(msg, prevFunction = null, nextFunction = null, param1 = null, param2 = null, stepId,
            param3 = null, param4 = false, skipFunction = null, nameSkipFunction = null, func_param4 = null,
            endGuideFunction = null) {

    /*
    * msg is the displayed text
    * prevFunction
    * nextFunction
    * param1 : parameters of the prevFunction {displayed text, params}
    * param2 : parameters of the nextFunction {displayed text, params}
    * stepId : id of the current Step
    * param3 : parameters of the skipFunction [idExploitation, ...furtherInformations] like warehouseOpen, productionOpen
    * param4 : JSON corresponding to the ended step of the last connexion {name, state, etc.} with the function to execute
    * skipFunction
    * nameSkipFunction : name of the skipFunction
    * func_param4 : function for the redirection at the beginning
    * endGuideFunction : the function that changes the state of the guide
    *
    * */

    Vue.toasted.clear()

    console.log('inside')
    return Vue.toasted.show(this._(msg), optionsGenerator(prevFunction, nextFunction, param1, param2, stepId,
      param3, param4, skipFunction, nameSkipFunction, func_param4, endGuideFunction))
  }
}

export const optionsGenerator = (prevFunction, nextFunction, param1, param2, stepId, param3, param4,
                                 skipFunction, nameSkipFunction, func_param4, endGuideFunction) => {

  console.log('nous sommes In')
  let actions = [
    {
      text: 'Terminer Guide',
      onClick: (e, toastObject) => {

        if (endGuideFunction instanceof Function) {
          // execute the function for the end of the guide
          console.log('the function for the guide')
          endGuideFunction()
        }
        if (stepId == 0) return

        // take the idExploitation through param3
        console.log('param3  est ', param3)
        let steps = Storage.get('guideExploitations')[param3[0]].guideSteps
        steps = retrieveSteps.lastStateSteps(steps, stepId, param3)

        console.log('ici')

        // sending the guide information of that exploitation
        Api.post('/assistant/save', {
          exploitationId: param3[0],
          status: 'IN_PROGRESS',
          steps: steps
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              // this.$store.commit('setGuideState')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
            this.error = error
          })
          .then(() => {

          })


      }
    },
  ]
  let basisOptions = {
    duration: null,
    position: 'top-right',
    theme: 'outline',
    iconPack: 'fontawesome',
    icon: 'fa-handshake-o',
    className: '',
    containerClass: '',
    singleton: true
  }
  console.log('nous sommes In2')
  if (param4) {
    actions.unshift(
      {
        text: param4.name,
        onClick: (e, toastObject) => {
          // take care of index of steps
          console.log('le resultat de la function est ', func_param4())
          func_param4()[param4.id - 1].redirect(...param4.params)
        }
      },
    )
  }

  console.log('nous sommes In3')
  // add the nextFunction action to the Toast
  if (nextFunction instanceof Function) {
    actions.unshift(
      {
        text: param2 == null ? 'Prochaine Etape' : param2.text,
        onClick: (e, toastObject) => {
          param2 == null || param2.params.length == 0 ? nextFunction() : nextFunction(...param2.params)
        }
      },
    )
  }

  console.log('nous sommes In4')
  // add the skipFunction action to the Toast
  if (param3 !== null && stepId != 5 && stepId != 0) {
    actions.unshift(
      {
        text: nameSkipFunction == null ? 'Sautez l\'Etape' : nameSkipFunction,
        onClick: (e, toastObject) => {
          skipFunction(...param3)
        }
      },
    )
  }

  // add the nextFunction action to the Toast
  if (prevFunction instanceof Function) {
    actions.unshift(
      {
        text: param1 == null ? 'Precedente Etape' : param1.text,
        onClick: (e, toastObject) => {
          param1 == null || param1.params.length == 0 ? prevFunction() : prevFunction(...param1.params)
        }
      },
    )
  }

  console.log({...basisOptions, action: actions})
  return {...basisOptions, action: actions}
}

export const functionGeneratorJSON = (text, ...params) => {
  return {text, params}
}

export const retrieveSteps = {


  lastStateSteps(steps, idStep, param3) {
    return steps.map(step => {
      const stepInter = step
      if (step.id != idStep + 1)
        delete stepInter.isCurrent
      else {
        stepInter.isCurrent = true
      }
      // to avoid modifying all the elements
      if (step.id == 4 && idStep == 3) stepInter.warehouseOpen = parseInt(param3[1])
      else if (step.id == 6 && idStep == 5) stepInter.productionOpen = parseInt(param3[1])
      return stepInter
    })
  }

}


import weather from 'openweather-apis'
import proj4 from 'proj4'

export const getGeographicData = (coordinatesEPSG3857, callback) => {
  const coordinatesEPSG4326 = proj4('EPSG:3857', 'EPSG:4326', coordinatesEPSG3857)
  weather.setCoordinate(coordinatesEPSG4326[1], coordinatesEPSG4326[0])
  weather.getAllWeather((err, data) => {
    if (err)
      callback(err)
    else
      callback(null, {
        weather: {
          iconUrl: 'http://openweathermap.org/img/w/' + data.weather[0].icon + '.png',
          temperature: data.main.temp,
          description: data.weather[0].description
        },
        localization: {
          city: data.name,
          country: data.sys.country
        }
      })
  })
}

export const DateFormatter = {
  forFilename(date) {
    if (!date)
      date = new Date()

    const y = date.getFullYear()
    const m = ('0' + (date.getMonth() + 1)).slice(-2)
    const d = ('0' + date.getDate()).slice(-2)

    const h = ('0' + date.getHours()).slice(-2)
    const i = ('0' + date.getMinutes()).slice(-2)
    const s = ('0' + date.getSeconds()).slice(-2)

    return `${y}-${m}-${d}_${h}h${i}min${s}s`
  },
  toSimpleDatetime(date) {
    if (!date)
      date = new Date()

    const y = date.getFullYear()
    const m = ('0' + (date.getMonth() + 1)).slice(-2)
    const d = ('0' + date.getDate()).slice(-2)

    const h = ('0' + date.getHours()).slice(-2)
    const i = ('0' + date.getMinutes()).slice(-2)

    return `${d}/${m}/${y} à ${h}:${i}`
  }
}

export const moneyFormat = (number, n, x, s, c) => {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = number.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};


import {createPdf} from 'pdfmake/build/pdfmake'
import fonts from 'pdfmake/build/vfs_fonts'
import IMAGE from '../assets/images.base64'

pdfMake.vfs = fonts.pdfMake.vfs

export const IO = {
  deepValue(obj, key) {
    try {
      let value = obj
      key.split('.').forEach(k => value = value[k])
      return value
    }
    catch (e) {
      return null
    }
  },
  async exportDataTable(data, filename, options, noIndexColumn) {
    Toast.loading('Génération du document...')

    try {
      const date = new Date()
      if (!noIndexColumn) {
        options.headers = [
          {title: '#', key: '$index', width: 'auto'},
          ...options.headers
        ]
        data = data.map((item, index) => ({$index: ++index, ...item}))
      }

      const document = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        header(currentPage, pageCount) {
        },
        footer(currentPage, pageCount) {
          if (currentPage > 1)
            return {
              text: 'Page ' + currentPage + ' / ' + pageCount,
              style: 'pagination'
            }
        },
        content: [
          {
            image: IMAGE["logo-square"],
            width: 250,
            height: 250,
            alignment: 'center'
          },
          {
            text: options.title,
            style: 'title'
          },
          {
            text: options.name,
            style: 'name'
          },
          {
            text: 'Généré le ' + DateFormatter.toSimpleDatetime(date),
            style: 'date'
          },
          {
            text: '',
            pageBreak: ''
          },
          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: options.headers.map(h => h.width),

              body: [
                // headers
                options.headers.map(h => ({
                  text: h.title,
                  style: ['td', 'th']
                })),

                // body
                ...await Promise.all(data.map(el => Promise.all(options.headers.map(h => {
                  if (!h.key.includes('picture')) {
                    return {
                      text: this.deepValue(el, h.key),
                      style: 'td'
                    }
                  }
                  return fetchAsBase64(h.asset(this.deepValue(el, h.key)))
                    .then(base64 => ({
                      image: base64,
                      height: 50,
                      width: 50,
                    }))
                }))))
              ]
            }
          }
        ],
        styles: {
          title: {
            alignment: 'center',
            fontSize: 18,
            margin: 20
          },
          name: {
            alignment: 'center',
            fontSize: 24,
            margin: 20,
            bold: true
          },
          date: {
            alignment: 'center',
            fontSize: 10,
            italics: true,
            margin: 20
          },
          th: {
            color: 'white',
            fillColor: '#808080',
            bold: true
          },
          td: {},
          pagination: {
            alignment: 'center',
            italics: true,
            fontSize: 9
          }
        }
      }

      console.warn(document)
      createPdf(document).download(DateFormatter.forFilename(date) + '__' + filename)
    }
    catch (e) {
      Toast.error(e)
      console.error(e.message)
      console.error(e)
    }
    finally {
      Toast.loadingToast.goAway()
    }
  }
}

