<template>
  <b-card class="py-2 px-3 mb-0" bg-variant="light" border-variant="light" no-body>
    <div class="text-muted" style="font-size: 12px">
      {{ $slots.default[0].text }}
    </div>
  </b-card>
</template>

<script>
  export default {
    name: "DescriptionMessage"
  }
</script>

<style scoped>

</style>
