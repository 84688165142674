// Register Bootstrap-Vue components
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import LiquorTree from 'liquor-tree'

import VueMonthlyPicker from 'vue-monthly-picker'

Vue.component('vue-monthly-picker', VueMonthlyPicker);


import {BAlert} from 'bootstrap-vue/esm/components/alert/alert'
import VueBootstrapTypehead from 'vue-bootstrap-typeahead'
import {BBadge} from 'bootstrap-vue/esm/components/badge/badge'
import {BBreadcrumb} from 'bootstrap-vue/esm/components/breadcrumb/breadcrumb'
import {BBreadcrumbItem} from 'bootstrap-vue/esm/components/breadcrumb/breadcrumb-item'
import {BBreadcrumbLink} from 'bootstrap-vue/esm/components/breadcrumb/breadcrumb-link'
import {BButton} from 'bootstrap-vue/esm/components/button/button'
import {BButtonClose} from 'bootstrap-vue/esm/components/button/button-close'
import {BButtonGroup} from 'bootstrap-vue/esm/components/button-group/button-group'
import {BButtonToolbar} from 'bootstrap-vue/esm/components/button-toolbar/button-toolbar'
import {BCard} from 'bootstrap-vue/esm/components/card/card'
import {BCardBody} from 'bootstrap-vue/esm/components/card/card-body'
import {BCardFooter} from 'bootstrap-vue/esm/components/card/card-footer'
import {BCardGroup} from 'bootstrap-vue/esm/components/card/card-group'
import {BCardHeader} from 'bootstrap-vue/esm/components/card/card-header'
import {BCardImg} from 'bootstrap-vue/esm/components/card/card-img'
import {BCarousel} from 'bootstrap-vue/esm/components/carousel/carousel'
import {BCarouselSlide} from 'bootstrap-vue/esm/components/carousel/carousel-slide'
import {BCol} from 'bootstrap-vue/esm/components/layout/col'
import {BCollapse} from 'bootstrap-vue/esm/components/collapse/collapse'
import {BContainer} from 'bootstrap-vue/esm/components/layout/container'
import {BDropdown} from 'bootstrap-vue/esm/components/dropdown/dropdown'
import {BDropdownDivider} from 'bootstrap-vue/esm/components/dropdown/dropdown-divider'
import {BDropdownHeader} from 'bootstrap-vue/esm/components/dropdown/dropdown-header'
import {BDropdownItem} from 'bootstrap-vue/esm/components/dropdown/dropdown-item'
import {BDropdownItemButton} from 'bootstrap-vue/esm/components/dropdown/dropdown-item-button'
import {BEmbed} from 'bootstrap-vue/esm/components/embed/embed'
import {BForm} from 'bootstrap-vue/esm/components/form/form'
import {BFormCheckbox} from 'bootstrap-vue/esm/components/form-checkbox/form-checkbox'
import {BFormCheckboxGroup} from 'bootstrap-vue/esm/components/form-checkbox/form-checkbox-group'
import {BFormFile} from 'bootstrap-vue/esm/components/form-file/form-file'
import {BFormGroup} from 'bootstrap-vue/esm/components/form-group/form-group'
import {BFormInput} from 'bootstrap-vue/esm/components/form-input/form-input'
import {BFormInvalidFeedback} from 'bootstrap-vue/esm/components/form/form-invalid-feedback'
import {BFormRadio} from 'bootstrap-vue/esm/components/form-radio/form-radio'
import {BFormRadioGroup} from 'bootstrap-vue/esm/components/form-radio/form-radio-group'
import {BFormRow} from 'bootstrap-vue/esm/components/layout/form-row'
import {BFormSelect} from 'bootstrap-vue/esm/components/form-select/form-select'
import {BFormText} from 'bootstrap-vue/esm/components/form/form-text'
import {BFormTextarea} from 'bootstrap-vue/esm/components/form-textarea/form-textarea'
import {BFormValidFeedback} from 'bootstrap-vue/esm/components/form/form-valid-feedback'
import {BImg} from 'bootstrap-vue/esm/components/image/img'
import {BImgLazy} from 'bootstrap-vue/esm/components/image/img-lazy'
import {BInputGroup} from 'bootstrap-vue/esm/components/input-group/input-group'
import {BInputGroupAddon} from 'bootstrap-vue/esm/components/input-group/input-group-addon'
import {BInputGroupAppend} from 'bootstrap-vue/esm/components/input-group/input-group-append'
import {BInputGroupPrepend} from 'bootstrap-vue/esm/components/input-group/input-group-prepend'
import {BInputGroupText} from 'bootstrap-vue/esm/components/input-group/input-group-text'
import {BJumbotron} from 'bootstrap-vue/esm/components/jumbotron/jumbotron'
import {BLink} from 'bootstrap-vue/esm/components/link/link'
import {BListGroup} from 'bootstrap-vue/esm/components/list-group/list-group'
import {BListGroupItem} from 'bootstrap-vue/esm/components/list-group/list-group-item'
import {BMedia} from 'bootstrap-vue/esm/components/media/media'
import {BMediaAside} from 'bootstrap-vue/esm/components/media/media-aside'
import {BMediaBody} from 'bootstrap-vue/esm/components/media/media-body'
import {BModal} from 'bootstrap-vue/esm/components/modal/modal'
import {BNav} from 'bootstrap-vue/esm/components/nav/nav'
import {BNavbar} from 'bootstrap-vue/esm/components/navbar/navbar'
import {BNavbarBrand} from 'bootstrap-vue/esm/components/navbar/navbar-brand'
import {BNavbarNav} from 'bootstrap-vue/esm/components/navbar/navbar-nav'
import {BNavbarToggle} from 'bootstrap-vue/esm/components/navbar/navbar-toggle'
import {BNavForm} from 'bootstrap-vue/esm/components/nav/nav-form'
import {BNavItem} from 'bootstrap-vue/esm/components/nav/nav-item'
import {BNavItemDropdown} from 'bootstrap-vue/esm/components/nav/nav-item-dropdown'
import {BNavText} from 'bootstrap-vue/esm/components/nav/nav-text'
import {BPagination} from 'bootstrap-vue/esm/components/pagination/pagination'
import {BPaginationNav} from 'bootstrap-vue/esm/components/pagination-nav/pagination-nav'
import {BPopover} from 'bootstrap-vue/esm/components/popover/popover'
import {BProgress} from 'bootstrap-vue/esm/components/progress/progress'
import {BProgressBar} from 'bootstrap-vue/esm/components/progress/progress-bar'
import {BRow} from 'bootstrap-vue/esm/components/layout/row'
import {BSpinner} from 'bootstrap-vue/esm/components/spinner/spinner'
import {BTab} from 'bootstrap-vue/esm/components/tabs/tab'
import {BTable} from 'bootstrap-vue/es/components/table/table'
import {BTabs} from 'bootstrap-vue/esm/components/tabs/tabs'
import {BTooltip} from 'bootstrap-vue/esm/components/tooltip/tooltip'


Vue.use(BootstrapVue)
Vue.use(LiquorTree)

Vue.component('b-alert', BAlert)
Vue.component('b-autocomplete-input', VueBootstrapTypehead)
Vue.component('b-badge', BBadge)
Vue.component('b-breadcrumb', BBreadcrumb)
Vue.component('b-breadcrumb-item', BBreadcrumbItem)
Vue.component('b-breadcrumb-link', BBreadcrumbLink)
Vue.component('b-button', BButton)
Vue.component('b-button-close', BButtonClose)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-button-toolbar', BButtonToolbar)
Vue.component('b-card', BCard)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-footer', BCardFooter)
Vue.component('b-card-group', BCardGroup)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-image', BCardImg)
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)
Vue.component('b-col', BCol)
Vue.component('b-collapse', BCollapse)
Vue.component('b-container', BContainer)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-dropdown-header', BDropdownHeader)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-item-button', BDropdownItemButton)
Vue.component('b-embed', BEmbed)
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-row', BFormRow)
Vue.component('b-form-row', BFormRow)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-text', BFormText)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-valid-feedback', BFormValidFeedback)
Vue.component('b-image', BImg)
Vue.component('b-image-lazy', BImgLazy)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-addon', BInputGroupAddon)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group-prepend', BInputGroupPrepend)
Vue.component('b-input-group-text', BInputGroupText)
Vue.component('b-jumbotron', BJumbotron)
Vue.component('b-link', BLink)
Vue.component('b-list-group', BListGroup)
Vue.component('b-list-group-item', BListGroupItem)
Vue.component('b-media', BMedia)
Vue.component('b-media-aside', BMediaAside)
Vue.component('b-media-body', BMediaBody)
Vue.component('b-modal', BModal)
Vue.component('b-nav', BNav)
Vue.component('b-nav-form', BNavForm)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-nav-text', BNavText)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-pagination', BPagination)
Vue.component('b-pagination-nav', BPaginationNav)
Vue.component('b-popover', BPopover)
Vue.component('b-progress', BProgress)
Vue.component('b-progress-bar', BProgressBar)
Vue.component('b-row', BRow)
Vue.component('b-spinner', BSpinner)
Vue.component('b-tab', BTab)
Vue.component('b-table', BTable)
Vue.component('b-tabs', BTabs)
Vue.component('b-tooltip', BTooltip)


// Toasted
import Toasted from 'vue-toasted'

Vue.use(Toasted, {})


// VueCustomScrollbar
import VueCustomScrollbar from 'vue-custom-scrollbar'

Vue.component('vue-custom-scrollbar', VueCustomScrollbar)


// Register custom components
import ButtonSpinner from './components/ButtonSpinner'
import CTable from './components/Table'
import StepProgress from './components/StepProgress'
import CInput from './components/Input'
import InfoMessage from './components/InfoMessage'
import DesriptionMessage from './components/DescriptionMessage'

Vue.component('button-spinner', ButtonSpinner)
Vue.component('c-table', CTable)
Vue.component('step-progress', StepProgress)
Vue.component('c-input', CInput)
Vue.component('info-message', InfoMessage)
Vue.component('description-message', DesriptionMessage)


// Weather settings
import weather from 'openweather-apis'
import {env} from "./helpers";

weather.setLang('fr')
weather.setUnits('metric')
weather.setAPPID(env('WEATHER_APPID'))
