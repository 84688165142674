// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import VueRouterMiddleware from 'vue-router-middleware'
import './init'
import App from './App'
import router from './router'
import {Store} from "./helpers";
import middlewares from './middlewares'
import TitleMixin from './mixins/TitleMixin'


Vue.mixin(TitleMixin)
Vue.use(VueRouterMiddleware, {router, middlewares})


new Vue({
  el: '#app',
  router,
  store: Store,
  template: '<App/>',
  components: {
    App
  }
})
