<template>
  <b-badge
    variant="ml-2"
    v-b-tooltip.hover.right="$slots.default[0].text"
    v-bind="$props"
  >
    <i class="fa fa-info-circle text-dark"></i>
  </b-badge>
</template>

<script>
    export default {
        name: "InfoMessage",
    }
</script>

<style scoped>

  .badge{
    cursor: pointer;
  }
  i{
    font-size: 15px;
  }
  
</style>
