import Vue from 'vue'
import Router from 'vue-router'
import {middleware} from 'vue-router-middleware'
import {Auth, Api, str, Toast} from "../helpers";
import exp_router from './exploitation'

// ToDo: Solve lazy loading problem

// Auth Views
const Login = () => import('@/views/auth/Login')
const Register = () => import('@/views/auth/Register')
const ForgotPassword = () => import('@/views/auth/ForgotPassword')
const PasswordReset = () => import('@/views/auth/PasswordReset')

// User Views
const Home = () => import('@/views/user/Home')
const Profile = () => import('@/views/user/Profile')
const Invitations = () => import('@/views/user/Invitations')
const Notifications = () => import('@/views/user/Notifications')

// Admin Views
const SuperAdminHome = () => import('@/views/admin/SuperAdminHome')

// Errors Views
const _404 = () => import('@/views/error/404')

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

Vue.use(Router)

export default new Router({
  mode: 'hash',
  history: true,
  linkActiveClass: 'open active',
  routes: [
    {
      path: '/',
      redirect: '/home',
      name: 'Index',
      meta: {
        breadcrumb: {
          text: 'Accueil'
        }
      },
      component: DefaultContainer,
      children: middleware('only-auth', [
        {
          path: '/home',
          name: 'Home',
          component: Home
        },
        {
          path: '/profile',
          name: 'Profile',
          component: Profile
        },
        {
          path: '/invitations',
          name: 'Invitations',
          component: Invitations
        },
        {
          path: '/notifications',
          name: 'Notifications',
          component: Notifications
        },
        {
          path: '/logout',
          name: 'Logout',
          component: {
            render(c) {
              return c('router-view')
            },
            beforeCreate() {
              Auth.logout()
              this.$router.push('/login')
            }
          }
        },
        ...middleware('only-exploitation-user', exp_router)
      ])
    },
    {
      path: '/super-admin',
      name: 'SuperAdmin',
      component: SuperAdminHome,
      children: middleware('only-auth', [
        {
          path: '/logout',
          name: 'LogoutAdmin',
          component: {
            render(c) {
              return c('router-view')
            },
            beforeCreate() {
              console.log("entrée dans la decinnexion")
              Auth.logout()
              this.$router.push('/')
            }
          }
        },
      ])
    },
    ...middleware('only-guest', [
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/register',
        name: 'Register',
        component: Register
      },
      {
        path: '/validate-account/:token',
        name: 'ValidateAccount',
        component: {
          render(c) {
            return c('router-view')
          },
          async beforeCreate() {
            const token = this.$route.params.token
            if (token) {
              try {
                const res = await Api.post('/validate-account', {token})
                const user = res.data.data
                if (res.data.status === 'success' && user) {
                  Auth.authenticateUser(user)
                  this.$router.push('/home')
                }
                else {
                  this.$router.push('/error/404')
                }
              }
              catch (error) {
                // ToDo: Manage error
                alert(str(error))
              }
            }
          }
        }
      },
      {
        path: '/invitation/decline/:token',
        name: 'InvitationDecline',
        component: {
          render(c) {
            return c('router-view')
          },
          async beforeCreate() {
            const token = this.$route.params.token
            if (token) {
              try {
                const res = await Api.post('/user/invitation/decline', {token})
                if (res.data.status === 'success' && res.data.data) {
                  Toast.success('Invitation déclinée avec succès !')
                  this.$router.push('/login')
                }
                else {
                  this.$router.push('/error/404')
                }
              }
              catch (error) {
                // ToDo: Manage error
                alert(str(error))
              }
            }
          }
        }
      },
      {
        path: '/invitation/accept/:token',
        name: 'InvitationAccept',
        component: {
          render(c) {
            return c('router-view')
          },
          async beforeCreate() {
            const token = this.$route.params.token
            const email = this.$route.query.email
            if (token) {
              try {
                const res = await Api.post('/user/invitation/accept', {token})
                if (res.data.status === 'success' && res.data.data) {
                  Toast.success('Invitation acceptée avec succès !')
                  this.$router.push('/register?email=' + email)
                }
                else {
                  this.$router.push('/error/404')
                }
              }
              catch (error) {
                // ToDo: Manage error
                alert(str(error))
              }
            }
          }
        }
      },
      {
        path: '/confirm-email/:token',
        name: 'ConfirmEmail',
        component: {
          render(c) {
            return c('router-view')
          },
          async beforeCreate() {
            const token = this.$route.params.token
            if (token) {
              try {
                const res = await Api.post('/confirm-email', {token})
                const user = res.data.data
                if (res.data.status === 'success' && user) {
                  Auth.authenticateUser(user)
                  this.$router.push('/home')
                }
                else {
                  this.$router.push('/error/404')
                }
              }
              catch (error) {
                // ToDo: Manage error
                alert(str(error))
              }
            }
          }
        }
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: '/password-reset/:token',
        name: 'PasswordReset',
        component: PasswordReset
      }
    ]),
    {
      path: '*',
      redirect: '/error/404',
      name: 'Any',
      component: {
        render(c) {
          console.log("entrée dans *")
          return c('router-view')
        }
      },
      children: [
        {
          path: '/error/404',
          name: 'Error404',
          component: _404
        }
      ]
    }
  ]
})
